figure {
	margin-block-end: 2rem;

	img {
		width: 100%;
		height: auto;

		&.img-border {
			border: 2px solid white;
		}
	}
}

.img-full {
	width: 100%;
	height: auto;
}

.img-logo-kwitt {
	margin-block-end: 2rem;
	width: 100%;
	max-width: 120px;
	height: auto;
}

.img-headline {
	width: 100%;
	height: auto;
	max-width: 280px;
	margin-block-end: 1.5em;
}