@font-face {
    font-family: 'frutiger_vrregular';
    src: url('../fonts/frutigervr/vr021___-webfont.ttf') format('truetype');
    src: url('../fonts/frutigervr/vr021___-webfont.woff') format('woff');
}

@font-face {
    font-family: 'frutiger_vrregular';
    src: url('../fonts/frutigervr/vr025___-webfont.ttf') format('truetype');
    src: url('../fonts/frutigervr/vr025___-webfont.woff') format('woff');
    font-style: italic;
}

@font-face {
    font-family: 'frutiger_vrregular';
    src: url('../fonts/frutigervr/vr051___-webfont.ttf') format('truetype');
    src: url('../fonts/frutigervr/vr051___-webfont.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'frutiger_vrregular';
    src: url('../fonts/frutigervr/vr085___-webfont.ttf') format('truetype');
    src: url('../fonts/frutigervr/vr085___-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}