*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: var(--font-family);
	@include fluid-type($min_width, $max_width, 16px, 18px);
	line-height: var(--line-height);
	color: var(--color-text);
}

h1 {
	margin: 0 0 2rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 32px, 48px);
	font-weight: 600;
	line-height: 1.2;
	color: var(--color-secondary);
}

h2 {
	margin: 0 0 1.5rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 24px, 32px);
	font-weight: 600;
	line-height: 1.2;
	color: var(--color-primary);
}

h3 {
	margin: 0 0 1rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 20px, 24px);
	font-weight: 600;
	line-height: 1.3;
	color: var(--color-primary);
}

h4 {
	margin: 0 0 1rem 0;
	padding: 0;
	font-weight: 600;
	line-height: 1.3;
	color: var(--color-primary);
}

p {
	margin: 0 0 1rem 0;

	&:last-child {
		margin: 0;
	}
}

b,
strong,
.bold {
	font-weight: 600;
}

ul {
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 0;
	}
}

a {
	color: var(--color-secondary);
	text-decoration: none;

	&:hover {
		color: var(--color-secondary);
		text-decoration: underline;
	}
}

.subtitle {
	color: var(--color-secondary);
}

.highlight {
    color: var(--color-secondary) !important;
}

.title-uppercase,
.uppercase {
    text-transform: uppercase;
}

.big {
    @include fluid-type($min_width, $max_width, 18px, 22px);
    font-weight: 600;
    font-style: italic;
}

.text-left {
    text-align: left;
}