.FormBuilder.InputfieldForm {
	margin: 1rem 0 0;
}

.InputfieldHeader:first-child::after {
	color: var(--color-secondary) !important;
}

.InputfieldForm .Inputfields {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
   align-self: flex-end;

}

.Inputfield.InputfieldText,
.Inputfield.InputfieldEmail,
.Inputfield.InputfieldSelect {
	width: calc(50% - 10px) !important;

	@include media-breakpoint-down(sm) {
		width: 100% !important;
	}
}

.FormBuilder.InputfieldForm .Inputfield {
	margin-bottom: 1rem;
}

.FormBuilder.InputfieldForm .Inputfield .InputfieldHeader {
	padding-bottom: 0;
}

.FormBuilder.InputfieldForm .Inputfield .InputfieldHeader {
	padding-bottom: 0;
	margin-bottom: 5px;
	font-size: 13px;
	font-weight: 600;
	color: var(--color-primary);
}

.InputfieldFormWidths .InputfieldColumnWidth > .InputfieldHeader,
.InputfieldFormWidths .InputfieldColumnWidth > .InputfieldContent {
	padding-left: 0;
}

.InputfieldFormWidths .InputfieldColumnWidthFirst > .InputfieldHeader,
.InputfieldFormWidths .InputfieldColumnWidthFirst > .InputfieldContent,
.InputfieldFormWidths .InputfieldColumnWidth + .InputfieldSubmit > .InputfieldHeader,
.InputfieldFormWidths .InputfieldColumnWidth + .InputfieldSubmit > .InputfieldContent,
.InputfieldFormWidths .InputfieldColumnWidthFirstTmp > .InputfieldHeader,
.InputfieldFormWidths .InputfieldColumnWidthFirstTmp > .InputfieldContent,
.InputfieldFormWidths .InputfieldColumnWidthFirst.InputfieldStateHidden + .Inputfield > .InputfieldHeader,
.InputfieldFormWidths .InputfieldColumnWidthFirst.InputfieldStateHidden + .Inputfield > .InputfieldContent {
	padding-left: 0 !important;
}

.FormBuilder.InputfieldForm input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="range"]),
.FormBuilder.InputfieldForm select:not([multiple]) {
	margin: 0;
	height: 40px;
	max-width: 100%;
	width: 100%;
	padding: 0 10px;
	border: 1px solid var(--color-border-form);
	background: white;
}

.FormBuilder.InputfieldForm {
	input {
		&[type="radio"],
		&[type="checkbox"],
		&[type="file"],
		&[type="range"] {
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
}

.InputfieldRadios {
	.InputfieldRadiosFloated {
		label {
			display: flex;
			align-items: center;
		}
	}
}

.FormBuilder.InputfieldForm .Inputfield select {
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	background-clip: padding-box;
	background-image: url("../img/layout/select-down.svg") !important;
	background-repeat: no-repeat;
	background-position: right 0.9rem center !important;
	background-size: 8px 5px !important;

	&::-ms-expand {
		display: none;
	}
}

.FormBuilder.InputfieldForm .InputfieldCheckbox .pw-no-select,
.FormBuilder.InputfieldForm .InputfieldCheckboxes .pw-no-select,
.FormBuilder.InputfieldForm .InputfieldRadios .pw-no-select {
	top: 0;
}

.FormBuilderErrors {
	padding: 15px;
	border: 1px solid var(--vr-rot);
	background-color: rgba(var(--vr-rot-rgb), 0.1);

	.alert-error {
		padding: 0;
		margin: 0;
		font-size: 13px;
		color: var(--vr-rot);
		background: transparent;

		:not(last-child) {
			margin: 0 0 0.5rem 0;
		}
	}
}

.FormBuilder {
	.alert-success {
		padding: 3rem 2rem;
		border: 2px solid var(--color-primary);
		background-color: transparent;
		color: var(--color-secondary);
        font-weight: 600;
        text-align: center;
	}
}

.InputfieldStateError {
	.InputfieldHeader {
		color: var(--vr-rot) !important;

		&:first-child:after {
			color: var(--vr-rot) !important;
		}
	}

	.InputfieldContent {
		input,
		select {
			border: 1px solid var(--vr-rot) !important;
		}

		span {
			color: var(--vr-rot) !important;
		}

		a {
			color: var(--vr-rot) !important;
			text-decoration: underline;
		}
	}

	.InputfieldHeader:first-child::after {
		color: var(--vr-rot) !important;
	}
}

.FormBuilder.InputfieldForm .input-error,
.errorMSG {
	color: var(--vr-rot);
	font-size: 12px;
   display: none;
}

.form-meta {
   display: block;
   width: 100%;
   margin: 1rem 0 0 0;
   font-size: 14px;
   color: var(--color-meta);
}

.InputfieldContent {
	button[type="submit"] {
		margin-top: .75rem;
		padding: 0.75rem 1.5rem;
		border-radius: 50vh;
		background-color: var(--color-secondary);
		@include effect();

		&:hover {
			background-color: var(--color-secondary-dark);
		}
	}
}

#Inputfield_bankname {
	pointer-events: none;
	background-color: rgba(white, 0.25);
}

.InputfieldForm input[type="radio"], .InputfieldForm input[type="checkbox"] {
   transform: scale(1);
   position: relative;
   top: 1px;
 }

.InputfieldCheckbox {
   accent-color: var(--color-secondary);

	label {
		position: relative;
		width: 100%;
	}

	input {
		position: absolute;
		top: 5px;
	}

	span {
		padding-left: 1.5rem;
		display: block;
		position: relative;
		top: 0;
		width: 100%;
	}
}

#ui-id-1 {
	position: absolute;
	padding: 1rem;
	width: 100% !important;
	max-height: 240px;
	overflow-y: auto;
	background-color: white;
	box-shadow: 0 10px 10px rgba($black, 0.1);
	z-index: 5;

	.ui-menu-item {
		cursor: pointer;
		list-style: none;

		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}

.ui-helper-hidden-accessible {
	display: none;
}

.InputfieldCheckbox.InputfieldStateRequired > .InputfieldContent > label > span.pw-no-select:after {
	content: "";
}

.FormBuilder.InputfieldForm .notes,
.FormBuilder.InputfieldForm .description {
	line-height: 1.4;
	margin-top: 0.25rem;
	margin-bottom: 0;
	opacity: 1;
	color: var(--color-meta);
}

.FormBuilder.InputfieldForm .tt {
   cursor: pointer;
	color: var(--vr-blau);
	opacity: 1;
 }

.FormBuilder,
.FormBuilder input,
.FormBuilder textarea,
.FormBuilder select,
.FormBuilder button,
.FormBuilder .ui-widget {
   font-size: 16px;
	color: var(--color-text);
}

.FormBuilder.InputfieldForm input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="range"]):focus,
.FormBuilder.InputfieldForm select:not([multiple]):focus {
	outline: 0 none;
	border-color: var(--color-secondary);
}

#ui-id-1 {
   width: 100%;
   max-width: 420px;

   li {
      font-size: 13px;
      color: var(--color-text);
   }
}

#wrap_Inputfield_teilnahmebedingungen .InputfieldHeader{
   display: none;
}
#wrap_Inputfield_alter .InputfieldHeader{
   display:none;
}
.InputfieldCheckbox span {
   display: contents !important;
}
#wrap_Inputfield_alter, #wrap_Inputfield_gewinnoptionen, #wrap_Inputfield_wunschtermin{
   width: 100% !important;
}
#wrap_Inputfield_bestaetigung_e_mail_adresse,
#wrap_Inputfield_geburtsdatum {
   align-self: flex-end;
   width: calc(50% - 10px)!important;
   
   @include media-breakpoint-down(sm) {
		width: 100% !important;
	}
}

.InputfieldRadiosStacked li {
   padding-inline-start: 0
}
.InputfieldRadiosStacked li::after {
   display: none;
}

[type="radio"] {
   accent-color: var(--color-secondary);
}

.Inputfield_geburtsdatum {
	.maxColHeightSpacer {
		height: 0 !important;
	}
}
.FormBuilder .alert-error {
    opacity: 1 !important;
}
.FormBuilder .alert-success {
    opacity: 1 !important;
}