main {
	display: block; //IE9-11 Support
}

.section {
	position: relative;
	padding-block-start: 40px;
	padding-block-end: 40px;

	@include media-breakpoint-up(sm) {
		padding-block-start: 60px;
		padding-block-end: 60px;
	}

	@include media-breakpoint-up(lg) {
		padding-block-start: 100px;
		padding-block-end: 100px;
	}

	&[data-bg="dark"] {
		background-color: var(--color-primary);
	}

	&[data-bg="poly"] {
		background-color: var(--vr-ultramarin);
		background-image: url("../img/layout/poly-blue.svg"), url("../img/layout/poly-orange.svg"), url("../img/layout/poly-orange.svg");
		background-position: top -60% left calc(50% + 50px), top 200px right -540px, bottom 0 left -480px;
		background-size: 400px, 640px, 640px;
		background-repeat: no-repeat;

		@include media-breakpoint-down(xl) {
			background-position: top -60% left calc(50% + 50px), top 200px right -540px, bottom 0 left -540px;
		}
	}

	&[data-bg="dark-poly"] {
		background-color: var(--color-primary);
		background-image: url("../img/layout/polygone-linke.svg"), url("../img/layout/polygone-rechts.svg");
		background-position: left -20% top -20%, right -20% bottom -20%;
		background-size: 40%, 40%;
		background-repeat: no-repeat;
	}

	&[data-bg="dark-fire"] {
		background-color: var(--color-primary);
		background-image: url("../img/layout/fire-left.jpg"), url("../img/layout/fire-right.jpg");
		background-position: left 0 top 0, right 0 bottom 0;
		background-size: 35%, 30%;
		background-repeat: no-repeat;
	}
}

.section-formular {
	position: relative;
	// padding: 20px;
	background-color: var(--color-bg-blue-light);

	//    @include media-breakpoint-up(md) {
	//       padding: 40px;
	// 	}
}

.container {
	padding-inline: 40px;

	@include media-breakpoint-down(sm) {
		padding-inline: 20px;
	}
}

.content {

	h2,
	h3 {
		&:not(:first-child) {
			margin-top: 2rem;
		}
	}

	ul {
		list-style: none;
	}

	li {
		position: relative;
		padding-inline-start: 1rem;
		margin-block-end: 0.75rem;

		&::after {
			position: absolute;
			content: "•";
			left: 0;
			top: 0.1rem;
		}
	}

	&[data-content="white"] {

		h2,
		h3,
		h4,
		p,
		li {
			color: white;
		}
	}

	&[data-align="center"] {
		text-align: center;
	}
}

.col-md-content {
	@include media-breakpoint-down(md) {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.col-sm-content {
	@include media-breakpoint-down(sm) {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.col-sm-center {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.list-lineup {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-block-start: 3rem;
	list-style: none;
	text-align: center;

	li {
		cursor: default;
		position: relative;
		margin-inline: 1rem;
		margin-block-end: 0.75rem;
		@include fluid-type($min_width, $max_width, 16px, 32px);
		color: white;
		@include effect();

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 5px;
			background-color: white;
			opacity: 0;
			animation: lineOut 0.3s forwards;
			@include effect();
		}

		@include media-breakpoint-down(sm) {
			margin-inline: 0.5rem;
			margin-block-end: 0.5rem;

			&::after {
				height: 3px;
			}
		}

		&:hover {
			color: var(--color-secondary);

			&::after {
				opacity: 1;
				animation: lineIn 0.3s forwards;
			}
		}
	}
}

@keyframes lineIn {
	from {
		transform: scaleX(0);
	}

	to {
		transform: scaleX(1);
	}
}

@keyframes lineOut {
	from {
		transform: scaleX(1);
	}

	to {
		transform: scaleX(0);
	}
}

.location {
	&__img {
		width: 100%;
		max-width: 200px;
		height: auto;
	}
}

.premium {
	&__list {
		margin-block-end: 4rem;
	}

	&__logo {
		width: 100%;
		max-width: 480px;
		height: auto;
		margin-inline: auto;
		margin-block-end: 3rem;
	}

	&__item {
		display: flex;
		margin-block-end: 4rem;

		// &:first-child,
		&:last-child {
			margin-block-end: inherit;
		}

		h3 {
			@extend .highlight;
			margin-inline-start: calc(140px + 4rem);
			margin-block-end: 2rem;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			text-align: center;

			h3 {
				margin-inline-start: inherit;
			}
		}
	}

	&__content {
		align-self: center;
	}

	&__icon {
		flex: 0 0 auto;
		aspect-ratio: 4/3;
		width: 140px;
		height: auto;
		margin-inline-end: 3rem;
		margin-block-end: auto;

		@include media-breakpoint-down(sm) {
			width: 120px;
			margin-inline: auto;
			margin-block-end: 2rem;
		}
	}
}

.icon-bar {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-block: 2rem;

	@include media-breakpoint-down(sm) {
		margin-block-end: 1rem;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-inline: 3rem;

		@include media-breakpoint-down(xl) {
			margin-inline: 1rem;
		}

		@include media-breakpoint-down(sm) {
			margin-inline: 0;
			margin-block-end: 1rem;
		}
	}

	&__img {
		width: 160px;
		height: auto;
		margin-block-end: 1rem;

		@include media-breakpoint-down(lg) {
			width: 120px;
		}

		@include media-breakpoint-down(sm) {
			width: 80px;
		}
	}

	// &__title {
	// 	font-size: 16px;
	// 	line-height: 1.4;
	// 	color: var(--color-primary);
	// }
}

.info-box {
	padding: 2rem;
	border: 2px solid var(--color-primary);

	@include media-breakpoint-down(sm) {
		padding: 1rem;
	}
}

.festival-info {
	display: grid;
	gap: 80px;

	&__item {
		position: relative;
		display: flex;
		gap: 60px;
		align-items: center;

		@include media-breakpoint-down(lg) {
			flex-wrap: wrap;
		}

		&:last-child {
			flex-direction: row-reverse;

			@include media-breakpoint-down(lg) {
				flex-direction: initial;
			}
		}
	}

	&__image {
		position: relative;
		flex-shrink: 0;
		max-width: 360px;

		@include media-breakpoint-down(xl) {
			max-width: 240px;
		}

		span {
			position: absolute;
			inset: auto -10px -10px auto;
			display: inline-block;
			color: white;
			padding: .1em .4em;
			font-weight: 600;
			font-size: 1.25em;
		}

		&.blue {
			rotate: -7deg;

			span {
				background-color: var(--vr-blau);
			}
		}

		&.orange {
			rotate: 7deg;

			span {
				background-color: var(--vr-orange);
			}
		}
	}

	&__content {
		display: flex;
		gap: 40px;
	}

	&__icon {
		flex: 0 0 auto;
		aspect-ratio: 1/1;
		width: 120px;
		height: auto;
		margin-inline-end: 3rem;
		margin-block-end: auto;

		&.blue {
			position: absolute;
			inset: auto auto -25% -15%;
			z-index: 50;

			@include media-breakpoint-down(lg) {
				inset: 10% auto auto 240px;
			}
		}

		&.orange {
			position: absolute;
			inset: -30% 0 auto auto;
			margin: initial;
			z-index: 50;

			@include media-breakpoint-down(lg) {
				inset: 10% auto auto 220px;
			}
		}

		@include media-breakpoint-down(lg) {
			width: 100px;
			margin-inline: auto;
			margin-block-end: 2rem;
		}
	}
}