$primary: #202a45;
$secondary: #e35412;

:root {
	--color-primary: #{$primary};
	--color-secondary: #{$secondary};
	--color-secondary-dark: #{darken($secondary, 10%)};

	--vr-ultramarin: #002d67;
	--vr-blau: #0066b3;
	--vr-orange: #ff6600;
	--vr-blau-a: #327dc8;
	--vr-blau-b: #649bd7;
	--vr-blau-c: #96bee6;
	--vr-blau-d: #e1ebf5;
	--vr-violett: #a0008c;
	--vr-dunkelgruen: #1b2d2e;
	--vr-rot: #a20032;
	--vr-gruen: #787800;
	--vr-blau-d-rgb: 225, 235, 245;
	--vr-rot-rgb: 162, 0, 50;

	--vr-orange-dark: #cc5200;
	--color-text: #333333;
	--color-title: #5a5a5a;
	--color-meta: #888888;
	--color-border: #cccccc;
	--color-border-form: #{rgba($primary, .2)};
	--color-bg-light: #{rgba($primary, .2)};
	--color-bg-blue-light: #{rgba($primary, .05)};

	--font-family: "frutiger_vrregular", Arial, sans-serif;
	--font-size: 18px;
	--font-size-sm: 16px;
	--font-weight: 400;
	--line-height: 1.6;
}

//Transition general
@mixin effect($class: all, $time: 150ms, $easing: ease-in-out) {
	-webkit-transition: $class $time $easing;
	-moz-transition: $class $time $easing;
	-o-transition: $class $time $easing;
	transition: $class $time $easing;
}

//Hyphenate
@mixin hyphenate() {
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

//Fluid Font
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 20px;
