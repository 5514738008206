.footer {
    padding-block: 3rem;

    ul {
        display: flex;
        justify-content: center;
        list-style: none;
        margin-block-end: 1rem;

        li {
            margin-inline: 1rem;
        }
    }

    &__meta {
        font-size: 14px;
        color: var(--color-meta);
        text-align: center;
    }
}