.hero {
	position: relative;

	&__img {
		position: relative;
		height: clamp(300px, 50vw, 700px);
		z-index: 5;

		// &::before {
		// 	position: absolute;
		// 	content: "";
		// 	inset: 0;
		// 	background-image: url("../img/layout/hero-gradient-blue.png"), url("../img/layout/hero-gradient-orange.png");
		// 	background-position: left top, right bottom;
        //     background-size: contain, 50%;
		// 	background-repeat: no-repeat;
		// 	z-index: 6;
		// }

		img {
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			z-index: 5;
		}
	}

	&__logo {
		position: absolute;
		top: 33%;
		left: 50%;
		width: clamp(240px, 50vw, 600px);
		height: auto;
		transform: translate(-50%, -50%);
		z-index: 10;
	}

	&__text {
		position: absolute;
		inset: auto 1em .5em auto;
		display: inline-block;
		color: white;
		opacity: .8;
		font-size: 13px;
		z-index: 15;
	}

	&__btn {
		position: absolute;
		bottom: 10%;
		left: 50%;
		height: auto;
		transform: translate(-50%, 0);
		z-index: 10;
	}
}
