.header {
	position: relative;
	display: flex;
	justify-content: space-between;
    align-items: center;
    padding-inline: 40px;
	width: 100%;
	height: 100px;
	background-color: white;
	box-shadow: 0 5px 20px rgba(black, 0.2);
	z-index: 1000;

	&__logo {
		display: inline-block;
		width: auto;
		height: 40px;
	}

	&__claim {
		display: inline-block;
		width: auto;
		height: 80px;
	}

    @include media-breakpoint-down(md) {
		&__logo {
            height: 35px;
        }
    
        &__claim {
            height: 60px;
        }
	}

    @include media-breakpoint-down(sm) {
        padding-inline: 20px;
        
		&__logo {
            height: 30px;
        }
    
        &__claim {
            height: 40px;
        }
	}
}
