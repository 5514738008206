.btn {
   display: inline-block;
   padding: .5rem 1.5rem;
   color: white;
   background-color: var(--color-secondary);
   border: 0;
   border-radius: 50vh;
   @include effect();

   &:hover,
   &:focus {
       color: $white;
       background-color: var(--color-secondary-dark);
       text-decoration: none;
   }

   &--sm {
       padding: .5rem 1rem;
       font-size: 18px;
   }

   &--xs {
       padding: .5rem 1rem;
       font-size: 16px;
   }

   &__bar {
       display: flex;
       flex-wrap: wrap;
       width: 100%;

       &--center {
           justify-content: center;
       }
   }
}